import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./formStyles.module.css";
import Grid from "@material-ui/core/Grid";

export default function Supplies(props) {
  const initialValues = {
    productManuCapability: "",
    manuServices: "",
    distributionCenter: "",
    warehouses: "",
    equipments: "",
    companySize: "",
    turnoverTime: "",
    industriesServed: "",
  };

  const validation = Yup.object().shape({
    productManuCapability: Yup.string().required("required"),
    manuServices: Yup.string().required("required"),
    companySize: Yup.string().required("required"),
    turnoverTime: Yup.string().required("required"),
    industriesServed: Yup.string().required("required"),
  });

  const handleSubmit = (values) => {
    console.log("submitted values", values);
    props.submitOrgSupplies(values);
    props.handleNext();
  };
  return (
    <div className={styles.formCard}>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.formStyles}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="productManuCapability">
                        Product Manufacturing Capability
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `40em` }}
                      className={styles.textInput}
                      name="productManuCapability"
                      placeholder="Example : IC boards"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="productManuCapability"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="manuServices">
                        Manufacturing Services Provided
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="manuServices"
                      placeholder="Example : batteries"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="manuServices"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="distributionCenter">
                        Distribution Centers
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="distributionCenter"
                      placeholder="Example : circuit boards, wires"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="warehouses">Warehouses</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="warehouses"
                      placeholder="Example : circuit boards, wires"
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="equipments">Equipments</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="equipments"
                      placeholder="Example : circuit boards, wires"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="companySize">
                        Company Size
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="companySize"
                      placeholder="Example : circuit boards, wires"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="companySize"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="turnoverTime">
                        Turnover Time
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="turnoverTime"
                      placeholder="Example : circuit boards, wires"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="turnoverTime"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="industriesServed">
                        Industries Served
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="industriesServed"
                      placeholder="Example : circuit boards, wires"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="industriesServed"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.buttonContainer}>
              <button
                type="submit"
                disabled={isSubmitting}
                className={styles.buttonStyles}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
