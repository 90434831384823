import React, { useState } from "react";
import styles from "./SignUpForm.module.css";
import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import useSignUp from "../../../../libs/authentication/hooks/useSignup";
export default function SignUpForm({ setSignUpDone }) {
  const navigate = useNavigate();
  const {
    username,
    email,
    password,
    orgName,
    setUsername,
    setEmail,
    setPassword,
    setOrgName,
    handleSignUp,
  } = useSignUp();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSuccess = (user) => {
    setSignUpDone(true);
  };

  const onFailure = (err) => {
    console.error("Sign up failed", err);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignUp(onSuccess, onFailure);
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <input
            type="text"
            className={styles.textInput}
            placeholder="Organization Name"
            required
            value={orgName}
            onChange={(event) => {
              setOrgName(event.target.value);
            }}
          />
        </div>
        <div className={styles.formGroup}>
          <input
            type="email"
            className={styles.textInput}
            placeholder="Email "
            required
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className={styles.formGroup}>
          <input
            type={showPassword ? "text" : "password"}
            className={styles.textInput}
            placeholder="Password"
            required
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <div className={styles.showPassword}>
            <input
              type="checkbox"
              id="rememberMe"
              name="rem"
              onClick={togglePasswordVisibility}
            />
            <label htmlFor="scales" className={styles.checkBoxPrimary}>
              {showPassword ? "Hide" : "Show"} Password
            </label>
          </div>
        </div>
        <div className={styles.formGroup}>
          <input
            type={showConfirmPassword ? "text" : "password"}
            className={styles.textInput}
            placeholder="Confirm Password"
            required
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
            }}
          />
          <div className={styles.showPassword}>
            <input
              type="checkbox"
              id="rememberMe"
              name="rem"
              onClick={toggleConfirmPasswordVisibility}
            />
            <label htmlFor="scales" className={styles.checkBoxPrimary}>
              {showConfirmPassword ? "Hide" : "Show"} Password
            </label>
          </div>
        </div>
        {/* <div className={styles.formGroup}>
          <input
            type="password"
            className={styles.textInput}
            placeholder="Confirm Password"
            required
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
            }}
          />
        </div> */}
        <input
          type="submit"
          className={styles.buttonSubmit}
          value="SIGN Up"
          disabled={password !== confirmPassword}
        />
      </form>
    </div>
  );
}
