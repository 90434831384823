import React from "react";
import styles from "./dashboard.module.css";
import {
  MdDone,
  MdOutlineLocalOffer,
  MdOutlineAttachMoney,
  MdOutlineChat,
} from "react-icons/md";
import { GiProgression } from "react-icons/gi";
import Grid from "@material-ui/core/Grid";
import DonutChart from "../common/donutChart/donutChart";
import Table from "../common/table/table";
export default function Dashboard() {
  return (
    <div>
      <div className={styles.tilesContainer}>
        <div className={styles.card}>
          <div className={styles.tileHeading}>
            <div className={styles.tileIcon}>
              <MdOutlineChat size={20} />
            </div>
            Enquiries
          </div>
          <div className={styles.tileCount}>28</div>
        </div>
        <div className={styles.card}>
          <div className={styles.tileHeading}>
            <div className={styles.tileIcon}>
              <MdOutlineLocalOffer size={20} />
            </div>
            Client offers
          </div>
          <div className={styles.tileCount}>45</div>
        </div>
        <div className={styles.card}>
          <div className={styles.tileHeading}>
            <div className={styles.tileIcon}>
              <MdOutlineAttachMoney size={20} />
            </div>
            Open Bids
          </div>
          <div className={styles.tileCount}>107</div>
        </div>
        <div className={styles.card}>
          <div className={styles.tileHeading}>
            <div className={styles.tileIcon}>
              <GiProgression size={20} />
            </div>
            In progress
          </div>
          <div className={styles.tileCount}>56</div>
        </div>
        <div className={styles.card}>
          <div className={styles.tileHeading}>
            <div className={styles.tileIcon}>
              <MdDone size={20} />
            </div>
            Completed
          </div>
          <div className={styles.tileCount}>96</div>
        </div>
      </div>
      <div className={styles.chartsWrapper}>
        {/* <Grid container className={styles.chartsWrapper}>
          <Grid item xs={3} md={3} lg={3}>
            <div className={styles.card}>
              <div className={styles.tileHeading}>
                <div className={styles.tileIcon}>
                  <MdOutlineChat size={20} />
                </div>
                Enquiries
              </div>
              <DonutChart />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={10}> */}
        <div className={styles.card}>
          <div className={styles.tileHeading}>
            <div className={styles.tileIcon}>
              <MdOutlineChat size={20} />
            </div>
            Top Orders
          </div>
          <Table />
        </div>
        {/* </Grid>
        </Grid> */}
      </div>
    </div>
  );
}
