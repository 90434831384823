import React, { useEffect, useState } from "react";
import styles from "./ProfileManufacture.module.css";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { GoLocation } from "react-icons/go";
import Rating from "@mui/material/Rating";
import axios from "axios";
import manulogo from "../../Assets/img/logoRap.png";
import { useLocation } from "react-router-dom";
export default function ProfileManufacture() {
  const [manuProfile, setManuProfile] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ManufacturerId = queryParams.get("ManufacturerId");
  const token =
    "eyJraWQiOiJcL2szaVVwN0hlWk1EYis5WWVBTitUY25yVjVuMndQTjFWakh3MjBiNFROND0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI1YWM0MTJjMi0xZTU2LTQ5MDEtYjllYi1hYmE3YTc1NjlmZjQiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX0hHc2tuRU94byIsImNsaWVudF9pZCI6IjI1cTE1amRmZTg1bGVrMzltOW84Mm03bXZlIiwib3JpZ2luX2p0aSI6ImY5NWJiODRhLWNhNjUtNDk4Yi1hNmZmLWQ0MWFmMmZlYzFhNiIsImV2ZW50X2lkIjoiZWMwZTZjOTktZmI1YS00YTY2LTk0MGUtM2Y4YTBmOGVkZjhkIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTcxMDMwNzUwNiwiZXhwIjoxNzEwMzExMTA2LCJpYXQiOjE3MTAzMDc1MDYsImp0aSI6Ijg4NjFiNDU3LTZjMWUtNGY3ZS1iYzJhLWNhYTFhN2ViNDdkZCIsInVzZXJuYW1lIjoidGVzdGluZyJ9.lKthRJtp3AJHepevW4pOmA7UFsUsUzSk2-kps7jHwsDb1KwIMfuW4JrhLMm9OO3ROJqRB3Nh1qOJbA9EdE7EENqWFSlHxE4EoJIEj2Drjdotp7jNdgEol_1FB9Nz7-z-CkB3kaWPmo__JmI6N4QKW46Ofj93uiCyhFTgfOhFz1D8TN1NtDAX4lqhWKxZWVZkWfw5OIn3W0ewuCRmWzKCMw9Lln48Q4rzUkQFCflHZC4wYw99UKfiZwhGXekJ8Yc2Al-iMiJYwHTKV98y4B3xEmLY5AGxEQ2LaAO_Ad4vtQck91sgJQf8yse_ebjnN7zB3NXFJLgOk4EznQELOdE-Tw";
  const fetchProfile = (ManufacturerId) => {
    axios
      .get(`https://dev.raprod.io/api/v1/manufacturers/${ManufacturerId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // Handle success, you can access the response data using `response.data`
        //setSearchResults(response.data.results);
        console.log("Response:", response);
        setManuProfile(response.data);
      })
      .catch((error) => {
        // Handle error, you can access the error details using `error`
        console.error("Error:", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchProfile(ManufacturerId);
  }, [ManufacturerId]);

  console.log("jhkdjshkld", manuProfile);
  return (
    <>
      {loading ? (
        <div>loading</div>
      ) : (
        <div>
          <div className={styles.profileCard}>
            <div className={styles.imgDiv}>
              {/* <img
            src={manulogo}
            alt="logo-manuf-profile"
            className={styles.companyLogo}
          /> */}
              <Avatar
                alt="Remy Sharp"
                src={manulogo}
                sx={{ width: 120, height: 120 }}
                className={styles.companyLogo}
              />
            </div>
            <div className={styles.profileHeader}>
              <div className={styles.companyTitle}>
                {manuProfile?.ManufacturerName}
              </div>
              <div className={styles.companySubTitle}>Manufacturer</div>
              <div className={styles.ratingsWrapper}>
                <Rating
                  name="read-only"
                  value={"4"}
                  readOnly
                  className={styles.rating}
                />
              </div>
              <div className={styles.profileButtonContainer}>
                <button className={styles.profileButton}>Connect</button>
                <button className={styles.profileButton}>Mark favorite</button>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.title}>Address</div>
            <div className={styles.contentWrapper}>
              <GoLocation className={styles.locationIcon} size={18} />
              <div className={styles.address}>
                1234 Charlotte way, charlotte, NC 28295, USA
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.title}>About</div>
            <div className={styles.contentWrapper}>
              <div className={styles.address}>{manuProfile?.Description}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
