import React from "react";
import ListCard from "./listCard/listCard";
export default function List({ searchResults }) {
  return (
    <div>
      {searchResults.map((result) => (
        <ListCard
          OrganizationName={result?.ManufacturerName}
          Description={result?.Description}
          City={result?.City}
          State={result?.State}
          key={result?.ManufacturerId}
          ManufacturerId={result?.ManufacturerId}
          ManuURL={result?.URL}
          rating={"4"}
        />
      ))}
    </div>
  );
}
