import React from "react";
import styles from "./table.module.css"; // Import CSS Module

const Table = () => {
  return (
    <div className={styles["table-container"]}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>ID</th>
            <th className={styles.th}>Name</th>
            <th className={styles.th}>Price</th>
            <th className={styles.th}>Email</th>
            <th className={styles.th}>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.tr}>
            <td className={styles.td}>1</td>
            <td className={styles.td}>John Doe</td>
            <td className={styles.td}>30</td>
            <td className={styles.td}>john@example.com</td>
            <td className={styles.td}>$4500</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>2</td>
            <td className={styles.td}>Jane Smith</td>
            <td className={styles.td}>25</td>
            <td className={styles.td}>jane@example.com</td>
            <td className={styles.td}>$4500</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>3</td>
            <td className={styles.td}>Jane Smith</td>
            <td className={styles.td}>25</td>
            <td className={styles.td}>jane@example.com</td>
            <td className={styles.td}>$4500</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>4</td>
            <td className={styles.td}>Jane Smith</td>
            <td className={styles.td}>25</td>
            <td className={styles.td}>jane@example.com</td>
            <td className={styles.td}>$4500</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>5</td>
            <td className={styles.td}>Jane Smith</td>
            <td className={styles.td}>25</td>
            <td className={styles.td}>jane@example.com</td>
            <td className={styles.td}>$4500</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>6</td>
            <td className={styles.td}>Jane Smith</td>
            <td className={styles.td}>25</td>
            <td className={styles.td}>jane@example.com</td>
            <td className={styles.td}>$4500</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>7</td>
            <td className={styles.td}>Jane Smith</td>
            <td className={styles.td}>25</td>
            <td className={styles.td}>jane@example.com</td>
            <td className={styles.td}>$4500</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
