import React, { useState } from "react";
import styles from "./SignUp.module.css";
import Grid from "@material-ui/core/Grid";
import bckGImg from "../../Assets/img/bck1.jpg";
import { FaGoogle } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Outlet, Link } from "react-router-dom";

//Components imports
import SignUpForm from "./SignUpForm/SignUpForm";
export default function SignUp() {
  const [signUpDone, setSignUpDone] = useState(true);
  const [isOtherSignUp, setIsOtherSignUp] = useState(false);
  const myStyles = {
    backgroundImage: `url(${bckGImg})`,
    height: "100vh",
    width: "100%",
    // marginTop: "-70px",
    // fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div style={myStyles}>
      <div className={styles.loginCardWrapper}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <div className={styles.loginCard}>
              <div style={{ marginTop: `20px` }}>
                <SignUpForm setSignUpDone={setSignUpDone} />
              </div>
              {signUpDone && (
                <div className={styles.signUpUserMessage}>
                  Please check your email for verification link.
                </div>
              )}

              {isOtherSignUp && (
                <div className={styles.loginUserMessage}>
                  — Or Sign Up With —
                </div>
              )}
              {isOtherSignUp && (
                <div className={styles.signInOptionsWrapper}>
                  <div className={styles.signInOtherOptionButton}>
                    <FaGoogle
                      size={18}
                      className={styles.iconMargStylesAdjust}
                    />
                  </div>
                  <div className={styles.signInOtherOptionButton}>
                    <FaFacebook
                      size={18}
                      className={styles.iconMargStylesAdjust}
                    />
                  </div>
                  <div className={styles.signInOtherOptionButton}>
                    <FaLinkedin
                      size={19}
                      className={styles.iconMargStylesAdjust}
                    />
                  </div>
                </div>
              )}
              {!signUpDone ? (
                <div
                  className={styles.loginUserMessage}
                  style={{ color: `#284B63` }}
                >
                  Already have an account, <Link to={`/login`}>Log In</Link>
                </div>
              ) : (
                <div
                  className={styles.loginUserMessage}
                  style={{ color: `#284B63`, marginBottom: "20px" }}
                >
                  <Link to={`/login`}>Log In</Link>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
