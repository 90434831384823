import { userPool } from "../../../userpool";
import { useEffect } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";

const useLogout = () => {
  const logout = () => {
    const cognitoUser = userPool.getCurrentUser(); // Get the currently authenticated user
    if (cognitoUser) {
      cognitoUser.signOut(); // Sign out the user
    }
    // Clear any additional logout-related actions if needed
  };

  // Optionally, you can include useEffect to automatically log out on component unmount
  useEffect(() => {
    return () => {
      logout(); // Automatically logout when the component unmounts
    };
  }, []);

  return logout;
};

export default useLogout;
