import Rating from "@mui/material/Rating";
import React from "react";
import styles from "./ListCard.module.css";
import { GoLocation } from "react-icons/go";
import { useNavigate } from "react-router-dom";
export default function ListCard({
  OrganizationName,
  Description,
  City,
  State,
  ManufacturerId,
  ManuURL,
  rating,
}) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/profile?ManufacturerId=${ManufacturerId}`);
  };
  return (
    <div>
      <div className={styles.card} key={ManufacturerId}>
        <div className={styles.title}>
          <div className={styles.name}>{OrganizationName}</div>
          <div className={styles.ratingsWrapper}>
            <Rating
              name="read-only"
              value={rating}
              readOnly
              className={styles.rating}
            />
          </div>
        </div>
        <div className={styles.locationWrapper}>
          <GoLocation className={styles.locationIcon} size={18} />
          <div className={styles.address}>{`${City}, ${State}`}</div>
        </div>
        <div className={styles.description}>{Description}</div>
        <div className={styles.services}>
          {/* {item?.services.map((x) => (
            <div className={styles.keyWordsButton}>{x}</div>
          ))} */}
          <button className={styles.viewMoreButton} onClick={handleNavigate}>
            View more
          </button>
        </div>
      </div>
    </div>
  );
}
