import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./formStyles.module.css";
import Grid from "@material-ui/core/Grid";

export default function OrgInfo(props) {
  const initialValues = {
    orgName: "",
    orgDescription: "",
    ordAddressOne: "",
    ordAddressTwo: "",
    ordAddressCity: "",
    ordAddressState: "",
    ordAddressCountry: "",
    ordAddressZipcode: "",
    ordRegistrationNumber: "",
    orgImportCode: "",
    orgExportCode: "",
  };

  const validation = Yup.object().shape({
    orgName: Yup.string().required("required"),
    orgDescription: Yup.string().required("required"),
    ordAddressOne: Yup.string().required("required"),
    ordAddressCity: Yup.string().required("required"),
    ordAddressState: Yup.string().required("required"),
    ordAddressCountry: Yup.string().required("required"),
    ordRegistrationNumber: Yup.string().required("required"),
    ordAddressZipcode: Yup.string().required("required"),
  });

  const handleSubmit = (values) => {
    console.log("Organization info", values);
    props.submitOrgInfo(values);
    props.handleNext();
  };
  return (
    <div className={styles.formCard}>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.formStyles}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgName">
                        Organization Name{" "}
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `40em` }}
                      className={styles.textInput}
                      name="orgName"
                      placeholder="aplha inc"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="orgName"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgDescription">
                        Organization Description
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      component="textarea"
                      className={styles.textAreaInput}
                      name="orgDescription"
                      placeholder="lorem pause orde vela cumoida"
                      required
                    />
                    <ErrorMessage
                      className={styles.errorMessage}
                      name="orgDescription"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="ordAddressOne">
                        Address line one
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="ordAddressOne"
                      placeholder="110 W 39th St"
                      required
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="ordAddressTwo">Address line two</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="ordAddressTwo"
                      placeholder="suit no 123"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="ordAddressCity">
                        City
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="ordAddressCity"
                      placeholder="New York City"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="ordAddressState">
                        State
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="ordAddressState"
                      placeholder="New York"
                      required
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="ordAddressCountry">
                        Country
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}

                      className={styles.textInput}
                      name="ordAddressCountry"
                      placeholder="USA"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="ordAddressZipcode">
                        Zipcode
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="ordAddressZipcode"
                      placeholder="12121"
                      required
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="ordRegistrationNumber">
                        Registration number
                        <span className={styles.errorMessage}>*</span>
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="ordRegistrationNumber"
                      placeholder="1234536789"
                      required
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgImportExportCode">Import code</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgImportCode"
                      placeholder="1234536789"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgExportCode">Export code</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgExportCode"
                      placeholder="1234536789"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.buttonContainer}>
              <button
                type="submit"
                disabled={isSubmitting}
                className={styles.buttonStyles}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
