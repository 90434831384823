// useSignUp.js
import { useState } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { userPool } from "../../../userpool";

const useSignUp = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [orgName, setOrgName] = useState("");

  const handleSignUp = (onSuccess, onFailure) => {
    const attributeList = [
      new CognitoUserAttribute({ Name: "email", Value: email }),
      new CognitoUserAttribute({
        Name: "custom:OrganizationName",
        Value: orgName,
      }),
    ];

    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        onFailure(err);
        return;
      }
      onSuccess(result.user);
    });
  };

  return {
    username,
    email,
    password,
    orgName,
    setUsername,
    setEmail,
    setPassword,
    setOrgName,
    handleSignUp,
  };
};

export default useSignUp;
