import React, { useState } from "react";
import styles from "./LoginForm.module.css";
import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import useLogin from "../../../../libs/authentication/hooks/useLogin";

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {
    username,
    password,
    setUsername,
    setPassword,
    handleLogin,
    isLoggedIn,
  } = useLogin();

  const onSuccess = (result) => {
    console.log("Login successful", result);
    // Redirect or set state indicating the user is authenticated
    const accessToken = result.getAccessToken().getJwtToken();
    localStorage.setItem("accessToken", accessToken);
    isLoggedIn();
    navigate("/home");
  };

  const onFailure = (err) => {
    console.error("Login failed", err);
    // Handle login failure
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(onSuccess, onFailure);
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <input
            type="text"
            className={styles.textInput}
            placeholder="Email Id"
            required
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
        </div>

        <div className={styles.formGroup}>
          <input
            type={showPassword ? "text" : "password"}
            className={styles.textInput}
            placeholder="Password"
            required
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <div className={styles.showPassword}>
            <input
              type="checkbox"
              id="rememberMe"
              name="rem"
              onClick={togglePasswordVisibility}
            />
            <label htmlFor="scales" className={styles.checkBoxPrimary}>
              {showPassword ? "Hide" : "Show"} Password
            </label>
          </div>
        </div>
        <input type="submit" className={styles.buttonSubmit} value="SIGN IN" />
      </form>
      <div className={styles.formGroupActions}>
        {/* <Grid container>
          <Grid item xs={6}>
            <div>
              <div>
                <input type="checkbox" id="rememberMe" name="rem" />
                <label htmlFor="scales" className={styles.checkBoxPrimary}>
                  Remember me
                </label>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>Forgot Password</div>
          </Grid>
        </Grid> */}
      </div>
    </div>
  );
}
