import React, { useState } from "react";
import styles from "./SearchDirectory.module.css";
import { RiCloseLine } from "react-icons/ri";

export default function SearchDirectory({ onSearch, setSearchQuery }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSearch, setSelectedSearch] = useState([]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (event) => {
    // Perform search action here, you can pass the searchTerm to a parent component
    // or make an API call for search results
    setSelectedSearch((prevSelectedSearch) => [
      // ...prevSelectedSearch,
      searchTerm,
    ]);
    setSearchQuery(searchTerm);
    if (onSearch) {
      onSearch(searchTerm);
    }
    setSearchTerm("");
  };

  const removeSearch = (index) => {
    // Create a new array without the selected search term at the given index
    // setSelectedSearch((prevSelectedSearch) =>
    //   prevSelectedSearch.filter((_, i) => i !== index)
    // );
    setSelectedSearch((prevSelectedSearch) => []);
    setSearchQuery(searchTerm);
    setSearchTerm("");
  };
  return (
    <>
      <div className={styles.searchContainer}>
        <div className={styles.searchWrapper}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
            className={styles.searchField}
          />
          <button onClick={handleSearch} className={styles.searchButton}>
            Search
          </button>
        </div>
      </div>
      <div className={styles.searchContainer}>
        <div className={styles.keywordsWrapper}>
          {selectedSearch.map((x, index) => (
            <div
              onClick={() => removeSearch(index)}
              className={styles.keyWordsButton}
            >
              {x}
              <div className={styles.closeIcon}>
                <RiCloseLine size={18} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
