import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import styles from "./formStyles.module.css";
import Grid from "@material-ui/core/Grid";

export default function Certification(props) {
  const initialValues = {
    orgCertification: "",
    orgAwards: "",
    orgLOR: "",
    numberOfEmployees: "",
  };

  const validation = Yup.object().shape({});

  const handleSubmit = (values) => {
    console.log("submitted values", values);
    props.submitAccolades(values);
    props.handleNext();
    props.onSubmit();
  };
  return (
    <div className={styles.formCard}>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.formStyles}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgCertification">Certification</label>
                    </div>
                    <Field
                      // style={{ width: `40em` }}
                      className={styles.textInput}
                      name="orgCertification"
                      placeholder=""
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgAwards">Awards</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgAwards"
                      placeholder=""
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgLOR">Recommendations</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgLOR"
                      placeholder=""
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="numberOfEmployees">
                        Number of employees
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="numberOfEmployees"
                      placeholder=""
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.buttonContainer}>
              <button
                type="submit"
                disabled={isSubmitting}
                className={styles.buttonStyles}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
