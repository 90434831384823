import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import styles from "./formStyles.module.css";
import Grid from "@material-ui/core/Grid";

export default function ContactInfo(props) {
  const initialValues = {
    orgContactFirstName: "",
    orgContactLastName: "",
    orgPhoneNumber: "",
    orgEmailID: "",
    orgMailAddressOne: "",
    orgMailAddressTwo: "",
    orgMailAddressCity: "",
    orgMailAddressState: "",
    orgMailAddressCountry: "",
    orgMailAddressZipcode: "",
    orgSocialMedia: "",
    orgWebsite: "",
  };

  const validation = Yup.object().shape({
    // orgName: Yup.string().required("required"),
  });

  const handleSubmit = (values) => {
    console.log("submitted values", values);
    props.submitOrgContactInfo(values);
    props.handleNext();
  };
  return (
    <div className={styles.formCard}>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.formStyles}>
              <Grid container>
                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgContactFirstName">First Name</label>
                    </div>
                    <Field
                      // style={{ width: `40em` }}
                      className={styles.textInput}
                      name="orgContactFirstName"
                      placeholder="Example : Joe"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgContactLastName">Last Name</label>
                    </div>
                    <Field
                      // style={{ width: `40em` }}
                      className={styles.textInput}
                      name="orgContactLastName"
                      placeholder="Example : Smith"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgPhoneNumber">Phone Number</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgPhoneNumber"
                      placeholder="Example : 123-756-8765"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgEmailID">Email Id</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgEmailID"
                      placeholder="Example : joeDoe@xyz.com"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgMailAddressOne">
                        Address Line One
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgMailAddressOne"
                      placeholder="Example : New York City"
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgMailAddressTwo">
                        Address Line Two
                      </label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgMailAddressTwo"
                      placeholder="Example : New York"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgMailAddressCity">City</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgMailAddressCity"
                      placeholder="Example : New York"
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgMailAddressState">State</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgMailAddressState"
                      placeholder="Example : NY"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgMailAddressCountry">Country</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgMailAddressCountry"
                      placeholder="Example : USA"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgMailAddressZipcode">Zipcode</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      className={styles.textInput}
                      name="orgMailAddressZipcode"
                      placeholder="Example : 12657"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgSocialMedia">Social Media</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      type="url"
                      className={styles.textInput}
                      name="orgSocialMedia"
                      placeholder=""
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={styles.fieldStyles}>
                    <div className={styles.labelStyles}>
                      <label htmlFor="orgWebsite">Website Link</label>
                    </div>
                    <Field
                      // style={{ width: `30em` }}
                      type="url"
                      className={styles.textInput}
                      name="orgWebsite"
                      placeholder=""
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.buttonContainer}>
              <button
                type="submit"
                disabled={isSubmitting}
                className={styles.buttonStyles}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
