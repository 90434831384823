import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { FaRegBuilding } from "react-icons/fa";
import { MdContactPhone } from "react-icons/md";
import { MdOutlineLocalShipping } from "react-icons/md";
import { TbCertificate } from "react-icons/tb";

//Component Import
import OrgInfo from "./subFormManu/OrgInfo";
import ContactInfo from "./subFormManu/ContactInfo";
import Supplies from "./subFormManu/Supplies";
import Certifications from "./subFormManu/Certifications";
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      backgroundImage: `linear-gradient(#52b69a, #74c69d)`,
    },
  },
  completed: {
    "& $line": {
      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      backgroundImage: `linear-gradient(#52b69a, #74c69d)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    //boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    backgroundColor: `#2a9d8f`,
  },
  completed: {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    backgroundColor: `#2a9d8f`,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <FaRegBuilding size={20} />,
    2: <MdContactPhone size={20} />,
    3: <MdOutlineLocalShipping size={25} />,
    4: <TbCertificate size={25} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    //marginRight: theme.spacing(1),
  },
  instructions: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  stepperTitle: {
    fontSize: 15,
    fontFamily: `Montserrat`,
    color: `#15212E `,
  },
}));

export default function CreateManuProfile() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [orgInfo, setOrgInfo] = useState();
  const [orgContactInfo, setOrgContactInfo] = useState();
  const [orgSupplies, setOrgSupplies] = useState();
  const [orgAccolades, setOrgAccolades] = useState();
  const steps = getSteps();

  console.log("orgInfo", orgInfo);
  console.log("org Contact Info", orgContactInfo);
  console.log("org Supplies Info", orgSupplies);
  console.log("org orgAccolades Info", orgAccolades);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    const ManuProfile = {
      ManufacturerName: orgInfo?.orgName,
      Description: orgInfo?.orgDescription,
      Country: orgInfo?.ordAddressCountry,
      State: orgInfo?.ordAddressState,
      City: orgInfo?.ordAddressCity,
      AddressLineOne: orgInfo?.ordAddressOne,
      AddressLineTwo: orgInfo?.ordAddressTwo,
      Zipcode: orgInfo?.ordAddressZipcode,
      RegistrationNumber: orgInfo?.ordRegistrationNumber,
      ImportNumber: orgInfo?.orgImportCode,
      ExportNumber: orgInfo?.orgExportCode,
      TurnoverTime: orgSupplies?.turnoverTime,
      URL: orgContactInfo?.orgWebsite,
    };

    axios
      .post("https://dev.raprod.io/api/v1/manufacturers", ManuProfile)
      .then((response) => {
        // Handle success, you can access the response data using `response.data`
        console.log("Response:", response);
      })
      .catch((error) => {
        // Handle error, you can access the error details using `error.response`
        console.error("Error:", error);
      });
  };

  function getSteps() {
    return [
      <div className={classes.stepperTitle}>Organization Information</div>,
      <div className={classes.stepperTitle}>Manufacturing Capability</div>,
      <div className={classes.stepperTitle}>Contact Information</div>,
      <div className={classes.stepperTitle}>Accolades</div>,
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <OrgInfo
            handleBack={handleBack}
            handleNext={handleNext}
            submitOrgInfo={setOrgInfo}
            onSubmit={handleSubmit}
          />
        );
      case 1:
        return (
          <Supplies
            handleBack={handleBack}
            handleNext={handleNext}
            submitOrgSupplies={setOrgSupplies}
            onSubmit={handleSubmit}
          />
        );
      case 2:
        return (
          <ContactInfo
            handleBack={handleBack}
            handleNext={handleNext}
            submitOrgContactInfo={setOrgContactInfo}
            onSubmit={handleSubmit}
          />
        );
      case 3:
        return (
          <Certifications
            handleBack={handleBack}
            handleNext={handleNext}
            submitAccolades={setOrgAccolades}
            onSubmit={handleSubmit}
          />
        );
      default:
        return "Unknown step";
    }
  }
  return (
    <div className={classes.root}>
      <Stepper
        style={{ backgroundColor: `transparent` }}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              Registration successful.
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Go to Profile
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, handleNext, handleBack)}
            </Typography>
            {/* <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
