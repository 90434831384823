import React from "react";
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement, Tooltip, Legend, Title);
Chart.defaults.plugins.tooltip.backgroundColor = "#eaf4f4";
Chart.defaults.plugins.tooltip.titleColor = "#3F76BF";
Chart.defaults.plugins.tooltip.borderColor = "#3F76BF";
Chart.defaults.plugins.tooltip.bodyColor = "#3F76BF";
Chart.defaults.plugins.legend.position = "bottom";
Chart.defaults.plugins.legend.title.font = "Montserrat";

const data = {
  labels: ["processed", "pending", "active"],
  datasets: [
    {
      data: [60, 20, 20],
      backgroundColor: ["#52b788", "#e9c46a", "#0077b6"],
      borderWidth: 2,
      radius: "80%",
      cutout: 120,
    },
  ],
};

function DonutChart() {
  return (
    <div>
      <Doughnut data={data} />
    </div>
  );
}

export default DonutChart;
