import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Outlet } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FaWpforms } from "react-icons/fa";
import { RiLogoutCircleRLine, RiAccountBoxLine } from "react-icons/ri";
import { RiHome2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import logo1 from "../Assets/img/logo1.png";
import logoRap from "../Assets/img/logoRap.png";
import axios from "axios";
import CreateManuProfile from "../ProfileCreate/CreateManuProfile";
import useLogout from "../../libs/authentication/hooks/useLogout";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: `#15212E`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 30,
  },
  menuButtonClose: {
    marginLeft: 15,
    marginRight: 15,
    outline: "none",
    display: "flex",
    alignItems: "center",
  },
  menuTitle: {
    marginLeft: 15,
    fontSize: "20px",
  },
  hide: {
    display: "none",
  },
  drawer: {
    backgroundColor: `#15212E`,
    color: "#fff",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: `#15212E`,
    color: "#fff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: `#15212E`,
    color: "#fff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    minHeight: "64px",
    //justifyContent: "left",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#F4F6F9",
    height: "100vh",
    //top: 500,
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = useLogout();
  const handleLogout = () => {
    localStorage.clear();
    logout();
    navigate("/");
  };

  const fetchUser = (token) => {
    axios
      .get("https://dev.raprod.io/api/v1/manufacturers", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("re", response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      console.log("toekn", token);
      fetchUser(token);
    }
  }, [localStorage.getItem("accessToken")]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <Avatar
              alt="logo"
              src={logoRap}
              // style={{ backgroundColor: `#f7ede2` }}
            />
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div
            onClick={handleDrawerClose}
            className={clsx(classes.menuButtonClose, {})}
          >
            <Avatar alt="logo" src={logoRap} />
            <div className={classes.menuTitle}>Raprod</div>
          </div>
        </div>

        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              navigate("/home");
            }}
          >
            <ListItemIcon>
              <RiHome2Line
                style={{ marginLeft: `8px`, color: `#f4f3ee` }}
                size={25}
              />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("manuProfileCreate");
            }}
          >
            <ListItemIcon>
              <RiAccountBoxLine
                style={{ marginLeft: `8px`, color: `#f4f3ee` }}
                size={28}
              />
            </ListItemIcon>
            <ListItemText>Create profile</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              navigate("/home/manuDirectory");
            }}
          >
            <ListItemIcon>
              <FaWpforms
                size={25}
                style={{ marginLeft: `8px`, color: `#f4f3ee` }}
              />
            </ListItemIcon>
            <ListItemText>Directory</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("/home/profile");
            }}
          >
            <ListItemIcon>
              <CgProfile
                size={25}
                style={{ marginLeft: `8px`, color: `#f4f3ee` }}
              />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List style={{ marginTop: `auto` }}>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <RiLogoutCircleRLine
                size={25}
                style={{ marginLeft: `8px`, color: `#f4f3ee` }}
              />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Outlet />
      </main>
    </div>
  );
}
