import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
  Routes,
} from "react-router-dom";

// Components imports
import Homepage from "../Components/Home/Homepage";
import ProfileManufacture from "../Components/Profile/Manufacture/ProfileManufacture";
import CreateManuProfile from "../Components/ProfileCreate/CreateManuProfile";
import Login from "../Components/Authentication/LogIn/Login";
import SignUp from "../Components/Authentication/SignUp/SignUp";
import DirectoryContainer from "../Components/Directory/DirectoryContainer";
import { AuthProvider } from "../libs/contexts/authetication/authContext";
import PrivateRoutes from "./privateRoutes";
import Dashboard from "../Components/Dashboard/dashboard";
// Testing
import TestingConponent from "../Components/TestingConponent";

export default function Main() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/home" element={<Homepage />}>
              <Route path="/home" element={<Dashboard />} />
              <Route path="/home/profile" element={<ProfileManufacture />} />
              <Route
                path="/home/manuProfileCreate"
                element={<CreateManuProfile />}
              />
              <Route
                path="/home/manuDirectory"
                element={<DirectoryContainer />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}
