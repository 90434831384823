import React, { useState } from "react";
import styles from "./DirectoryContainer.module.css";
import SearchDirectory from "./Search/SearchDirectory";
import List from "./List";
import axios from "axios";
import { isEmpty } from "lodash";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
export default function DirectoryContainer() {
  const [query, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  console.log("Search param", query);
  const fetchSearches = (searchParam) => {
    const start = 0;
    const size = 10;
    const searchObj = {
      query: searchParam,
      start: start,
      size: size,
    };
    axios
      .post("https://dev.raprod.io/api/v1/manufacturers/search", searchObj)
      .then((response) => {
        // Handle success, you can access the response data using `response.data`
        setSearchResults(response.data.results);
        console.log("Response:", response.data.results);
      })
      .catch((error) => {
        // Handle error, you can access the error details using `error`
        console.error("Error:", error);
      });
  };

  return (
    <div className={styles.container}>
      <SearchDirectory
        setSearchQuery={setSearchQuery}
        onSearch={fetchSearches}
      />
      {isEmpty(query) && (
        <div className={styles.defaultText}>
          <MdOutlineConnectWithoutContact
            className={styles.connectIcon}
            size={35}
          />
          Search products or services to connect to Manufacturers
        </div>
      )}
      {!isEmpty(query) && <List searchResults={searchResults} />}
    </div>
  );
}
