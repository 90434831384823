// useLogin.js
import { useState } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { userPool } from "../../../userpool";
import { useAuth } from "../../contexts/authetication/authContext";
const useLogin = () => {
  const { isLoggedIn } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (onSuccess, onFailure) => {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess,
      onFailure,
    });
  };

  return {
    username,
    password,
    setUsername,
    setPassword,
    handleLogin,
    isLoggedIn,
  };
};

export default useLogin;
